import React, { Component } from "react";
import { Row, Col, Breadcrumb, message, Button, Form, Popconfirm, Radio, Input, Select, Tooltip, Table, Menu, Popover, Dropdown, TreeSelect, InputNumber, DatePicker, Modal, Spin, Empty, Cascader, List, Tabs } from "antd";
import { Ossupload } from "../../common/upLoad";
import { FlieList, fullScrImg, popoverTable } from "../../common";
import { Axios } from "../../axios";
import { PlusOutlined, InfoCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { AreaSelect, Imgbox } from "../../common";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./acustomerManage.scss";
import Filter from "../../common/filter";
import { Shengpi2, Tables } from "./Common";
import filter from "../../common/filter";
import { bindActionCreators } from "redux";
import { change_operInfo } from "../../redux/actions/operInfo";
import moment from "moment";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;
let BackGroundColor = {
  1: "rgba(250, 141, 0, 1)",
  2: "rgba(43, 208, 90, 1)",
  3: "rgba(86, 86, 86, 1)",
  5: "rgba(86, 86, 86, 1)",
  7: "rgba(227, 77, 89, 1)",
  0: "rgba(227, 77, 89, 1)",
};
class CustomerDetailsM extends Component {
  TablesRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      brokerDetails: {},
      tData: [],
      loading: false,
      totalNum: 0,
      currentPage: 1,
      brokeFileList: [],
      legalServiceOrder: null,
      comtotalNum: 0,
      InformationList: {},
      OrderinformationList: {},
      historyList: [],
      legalCloudDocs: [],
      userInvoiceInfo: {},
      Entrusted: {},
      sendJson: {},
      activeKey: "1",
      legalCloudOperHis: [],
      isHaveEsign: false,
      isCanSign: false,
    };
  }

  getDet = async () => {
    try {
      let res = await Axios("get", "/order/detail/" + window.brokerId, {});
      if (res.code == "200") {
        let brokeFileList = [];
        let legalCloud = [];
        if (res.data.orderInfo.picUrl) {
          res.data.orderInfo.picUrl.split(",").forEach((v, i) => {
            brokeFileList.push({ docUrl: v, id: i });
          });
        }
        if (res.data.esigns) {
          let arr = {};
          res.data.esigns.map(v => {
            arr = v.fileInfos[0] || {};
            arr.esignStatus = v.esignStatus;
            legalCloud.push(arr);
          });
        }
        this.setState({
          legalCloudDocs: legalCloud,
          InformationList: res.data.customInfo || {},
          Entrusted: {
            entrustedChargeMode: res.data.entrustedChargeMode || "",
            entrustedCollectionFeeRate: res.data.entrustedCollectionFeeRate || "",
            entrustedEarlyAmt: res.data.entrustedEarlyAmt || "",
            entrustedFreeCount: res.data.entrustedFreeCount || "",
          },
          legalServiceOrder: res.data.legalServiceOrder,
          OrderinformationList: res.data.orderInfo,
          historyList: res.data.historyList,
          brokeFileList,
          legalCloudOperHis: res.data.legalCloudOperHis,
          userInvoiceInfo: res.data.userInvoiceInfo || {},
          sendJson: res.data.userInvoiceInfo ? JSON.parse(res.data.userInvoiceInfo.sendJson) : {},
          isHaveEsign: res.data.isHaveEsign || false,
          isCanSign: res.data.isCanSign || false,
        });
        this.TablesRef.current.getOperhisList();
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount () {
    window.brokerId = this.props.match.params.id;
    this.getDet();
  }
  tabsChange = e => {
    this.setState({
      activeKey: e,
    });
  };

  render () {
    const brokerDetails = {};
    const companyInfo = {};
    const { InformationList, OrderinformationList, Entrusted, userInvoiceInfo, historyList, sendJson, legalCloudDocs, legalCloudOperHis, isHaveEsign, isCanSign } = this.state;
    const cStyle = {
      padding: "12px 24px 12px 24px",
      marginBottom: "15px",
      borderRadius: "4px",
      background: "#fff",
      fontSize: "14px",
    };
    const tStyle = {
      color: "#222",
      lineHeight: "30px",
      fontWeight: "650",
      fontSize: "16px",
      margin: "0px -24px 15px -24px",
      borderBottom: "1px solid #efe5e5",
      padding: "0 24px 8px 24px",
    };
    const tStyle1 = {
      color: "#222",
      lineHeight: "30px",
      fontWeight: "650",
      fontSize: "16px",
      margin: "0px -24px 15px -24px",
      borderBottom: "1px solid #efe5e5",
      padding: "0 0 8px 0",
    };
    const dStyle = {
      color: "#222",
      lineHeight: "30px",
      margin: "10px 0px 0px 0px",
    };
    const operInfo = this.props.operInfo;
    let QuanXian = true;
    let fileList = [];
    if (this.state.legalServiceOrder && this.state.legalServiceOrder.offlineSignContract) {
      let tempfileList = this.state.legalServiceOrder.offlineSignContract.split(",");
      tempfileList.forEach((e, index) => {
        fileList.push({
          docUrl: e,
          id: index,
        });
      });
    }
    let OrderStatus = filter.ifStatus(OrderinformationList);
    return (
      <div className="pageContainer">
        <Row className="headNav" type="flex" justify="space-between" style={{ width: "100%" }}>
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/OrderQuery">直客订单管理</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>直客订单管理</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">直客订单管理详情</span>
            <Shengpi2 refreshTable={this.getDet} index={"5"} detailCustId={window.brokerId} brokerUserId={InformationList.brokerUserId}>
              <Button type="primary" disabled={[0, "0", "G", "C"].includes(OrderinformationList.status)}>
                分配业务员
              </Button>
            </Shengpi2>
            {OrderinformationList.payMode == "5" && OrderinformationList.status == "1" ? <AddBroker isHaveEsign={isHaveEsign} getDet={this.getDet} OrderinformationList={OrderinformationList} /> : ""}
            {OrderinformationList.winCoinInvoiceStatus == "0" && OrderinformationList.payAmt ? (
              <Dropdown
                overlay={
                  <Menu>
                    {OrderinformationList.payMethod == "1" || OrderinformationList.payMethod == "2" || !OrderinformationList.payMethod ? (
                      <Menu.Item>
                        <Fapiao
                          getDet={this.getDet}
                          Info={{
                            ...OrderinformationList,
                            ...InformationList,
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              width: "100%",
                            }}
                          >
                            增值税电子普通发票
                          </p>
                          <span
                            style={{
                              color: "#A7AAAD",
                              display: "inline-block",
                              width: "100%",
                              fontSize: "12px",
                            }}
                          >
                            电子发票
                          </span>
                        </Fapiao>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {OrderinformationList.payMethod == "2" ? (
                      <Menu.Item>
                        <ZhuanFapiao
                          getDet={this.getDet}
                          Info={{
                            ...OrderinformationList,
                            ...InformationList,
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              width: "100%",
                            }}
                          >
                            增值税电子专用发票
                          </p>
                          <span
                            style={{
                              color: "#A7AAAD",
                              display: "inline-block",
                              width: "100%",
                              fontSize: "12px",
                            }}
                          >
                            仅限公对公转账开票
                          </span>
                        </ZhuanFapiao>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </Menu>
                }
                placement="bottomLeft"
              >
                <Button
                  type="primary"
                  style={{
                    marginRight: "12px",
                    float: "right",
                  }}
                >
                  开发票
                </Button>
              </Dropdown>
            ) : (
              <Button type="primary" style={{ marginRight: "12px", float: "right" }} disabled>
                开发票
              </Button>
            )}
            {userInvoiceInfo.downloadUrl && userInvoiceInfo.status == 1 ? (
              <Button
                onClick={() => {
                  filter.DownloadImage(userInvoiceInfo.downloadUrl);
                }}
                type="primary"
                style={{ marginRight: "12px", float: "right" }}
              >
                下载发票
              </Button>
            ) : (
              ""
            )}

            <div
              style={{
                background: OrderStatus == "已开票" ? "#1890ff" : "#f5f5f5",
                color: OrderStatus == "已开票" ? "#fff" : "rgba(0, 0, 0, 0.25)",
                lineHeight: "26px",
                padding: "3px 10px",
                float: "right",
                borderRadius: "4px",
                marginRight: "12px",
              }}
            >
              发票状态： {OrderStatus || "-"}
            </div>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div style={cStyle}>
            <div style={cStyle}>
              <div style={tStyle}>客户基本信息</div>
              <Row style={dStyle}>
                <Col span={8}>
                  客户ID：
                  {InformationList.currentUserId || "暂无"}
                </Col>
                <Col span={8}>
                  客户名称：
                  {InformationList.companyName || "暂无"}
                </Col>
                <Col span={8}>联系人：{InformationList.linkman || "暂无"}</Col>
              </Row>
              <Row style={dStyle}>
                <Col span={8}>
                  联系电话：
                  {InformationList.mobileNo || "暂无"}
                </Col>
                <Col span={8}>
                  所在地：{InformationList.province || "暂无"} {InformationList.city || ""}
                </Col>
                <Col span={8}>
                  业务员：
                  {InformationList.brokerUserName || "暂无"}
                </Col>
              </Row>
              <Row style={dStyle}>
                <Col span={8}>
                  代理商：
                  {InformationList.brokerName || "暂无"}
                </Col>

                <Col span={8}>
                  所属行业：
                  {InformationList.firstCategory || "暂无"} {InformationList.secondCategory || ""} {InformationList.thirdCategory || ""}{InformationList.fourCategory || ""}
                </Col>
                <Col span={8}>
                  首要需求：
                  {InformationList.firstDemand ? filter.FirstDemandType(InformationList.firstDemand) : "暂无"}
                </Col>
              </Row>
              <Row style={dStyle}>
                <Col span={8}>企业规模： {InformationList.companySpec ? filter.CompanySpec(InformationList.companySpec) : "暂无"}</Col>
              </Row>
            </div>
          </div>
          {OrderinformationList.status != 1 || OrderinformationList.picUrl ?
            <div style={cStyle}>
              <div style={cStyle}>
                <div style={tStyle}>非诉清收服务费比例管理</div>
                <Row style={dStyle}>
                  <Col span={8}>可免费清收笔数：{Entrusted.entrustedFreeCount || "-"}</Col>
                  <Col span={8}>超笔数后收费模式：{filter.ServiceChargeMode(Entrusted.entrustedChargeMode)}</Col>
                  <Col span={8}>前期收费：{Entrusted.entrustedEarlyAmt || "-"}</Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={8}>回款后收费比例：{Entrusted.entrustedCollectionFeeRate ? Math.trunc(Entrusted.entrustedCollectionFeeRate * 100) + "%" : "0%"}</Col>
                </Row>
              </div>
            </div> : ''}
          <div style={cStyle}>
            <div style={cStyle}>
              <div style={tStyle}>订单信息</div>
              <Row style={dStyle}>
                <Col span={8}>
                  订单号：
                  {OrderinformationList.orderId || "暂无"}
                </Col>
                <Col span={8}>
                  套餐/单项服务名称：
                  {OrderinformationList.costAmt ? "￥" + OrderinformationList.costAmt + "/" + OrderinformationList.serviceMode : "暂无"}
                </Col>
                <Col span={8}>
                  购买时间：
                  {OrderinformationList.payTime ? OrderinformationList.payTime.replace("T", "    ") : "暂无"}
                </Col>
              </Row>
              <Row style={dStyle}>
                <Col span={8}>
                  支付金额：
                  {OrderinformationList.payAmt ? OrderinformationList.payAmt + "元" : "暂无"}
                </Col>
                <Col span={8}>
                  支付方式：
                  {filter.PayModeInfo(OrderinformationList.payMode) || "暂无"}
                </Col>
                <Col span={8}>
                  业务员：
                  {InformationList.brokerUserName || "暂无"}
                </Col>
              </Row>
              <Row style={dStyle}>
                <Col span={8}>订单状态： {filter.OrderQuerystatus(OrderinformationList.status) || "暂无"}</Col>
                <Col span={8}>
                  退款操作时间：
                  {OrderinformationList.refundDate ? filter.date(OrderinformationList.refundDate) + "  " + filter.time(OrderinformationList.refundTime) : "暂无"}
                </Col>
                <Col span={8}>操作人： {OrderinformationList.status == "G" ? "总部云法务" : "暂无"}</Col>
              </Row>
            </div>
          </div>
          <div style={cStyle}>
            <div style={cStyle}>
              <Tabs
                activeKey={this.state.activeKey}
                onChange={e => this.tabsChange(e)}
                tabBarExtraContent={
                  this.state.activeKey === "2" ? (
                    <span>
                      <Contract
                        refreshTable={this.getDet}
                        InformationList={{
                          isCanSign,
                          ...InformationList,
                          ...OrderinformationList,
                        }}
                      />
                      <ContractButton
                        refreshTable={this.getDet}
                        InformationList={{
                          isCanSign,
                          ...InformationList,
                          ...OrderinformationList,
                        }}
                      />
                    </span>
                  ) : null
                }
              >
                {" "}
                <Tabs.TabPane
                  tab={
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      线下签约合同
                    </span>
                  }
                  key="1"
                >
                  <div type="flex">
                    <Ossupload
                      callbackData={{ test: "123" }}
                      callback={async (filename, file, info, callbackData) => {
                        if (info.status == 200) {
                          try {
                            let ossUrl = "";
                            if (this.state.legalServiceOrder && this.state.legalServiceOrder.offlineSignContract) {
                              ossUrl = this.state.legalServiceOrder.offlineSignContract;
                            }
                            let send = {
                              offlineSignContract: ossUrl + "," + window.winhc_oss_url + filename,
                              orderId: window.brokerId,
                            };

                            console.log("send=", send);

                            try {
                              let res = await Axios("post", "/order/offline/sign", send);
                              console.log("上传文件==", res);
                              if (res.code === "200") {
                                message.success(res.message);
                                this.getDet(); // 刷新列表数据;
                              } else {
                                message.error(res.message);
                              }
                            } catch (error) {
                              console.log(error);
                            }
                          } catch (err) {
                            console.log("err", err);
                            message.error(err.message);
                          }
                        }
                      }}
                      beforeUpload={(file, up) => {
                        console.log(file, up);
                        if (file.size > 1024 * 1024 * 10 * 30) {
                          message.error("请选择小于300MB文件！");
                          return false;
                        }
                      }}
                    >
                      <Button
                        style={{
                          marginTop: "-20px",
                          marginRight: "20px",
                        }}
                      >
                        <UploadOutlined />
                        上传文件
                      </Button>
                    </Ossupload>
                    <FlieList
                      fileList={fileList}
                      QuanXian={QuanXian}
                      close={async (url, index, id) => {
                        // console.log(url, index, id);
                        let tempfileList = this.state.legalServiceOrder.offlineSignContract.split(",");
                        tempfileList.splice(index, 1);
                        try {
                          let send = {
                            offlineSignContract: tempfileList.join(","),
                            orderId: window.brokerId,
                          };

                          try {
                            let res = await Axios("post", "/order/offline/sign", send);
                            console.log("上传文件==", res);
                            if (res.code === "200") {
                              message.success("删除成功");
                              this.getDet(); // 刷新列表数据;
                            } else {
                              message.error(res.message);
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        } catch (err) {
                          console.log("err", err);
                          message.error(err.message);
                        }
                      }}
                    />
                  </div>
                  <div style={{ marginTop: 5 }}>支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      电子合同
                    </span>
                  }
                  key="2"
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {legalCloudDocs.length > 0 ? (
                      legalCloudDocs.map(v => {
                        return (
                          <a
                            target="_blank"
                            href={v.fileDownloadUrl}
                            style={{
                              width: "391px",

                              background: "#FAFAFA",
                              borderRadius: "8px",
                              color: "#242A32",
                              border: "1px solid #EEEEEE",
                              padding: "8px 12px",
                              position: "relative",
                              margin: "0 16px 12px 0",
                              cursor: "pointer",
                              display: "flex",
                            }}
                          >
                            <img
                              style={{
                                width: "45px",
                                height: "48px",
                                marginRight: "4px",
                              }}
                              src={require("../../assets/images/p.png")}
                              alt=""
                            />
                            <span
                              style={{
                                position: "absolute",

                                color: " #FFFFFF",
                                padding: "2px 4px",
                                background: BackGroundColor[v.esignStatus],
                                fontFamily: "PingFangSC-Medium, PingFang SC",
                                fontSize: "12px",
                                top: "0",
                                left: "0",
                                borderRadius: "4px 4px 2px 2px",
                              }}
                            >
                              {filter.ElectroniCesignStatus(v.esignStatus)}
                            </span>
                            <div>{v.fileName}</div>
                          </a>
                        );
                      })
                    ) : (
                      <span style={{ color: "#818181" }}>暂无电子合同，请关联电子合同</span>
                    )}
                  </div>
                </Tabs.TabPane>
                {OrderinformationList.agreementRead == 1 ? (
                  <Tabs.TabPane
                    tab={
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        线上协议
                      </span>
                    }
                    key="3"
                  >
                    <span style={{ color: "#818181" }}>用户已同意线上协议</span>
                  </Tabs.TabPane>
                ) : (
                  ""
                )}
              </Tabs>
            </div>
          </div>{" "}
          <div style={cStyle}>
            <div style={cStyle}>
              <div style={tStyle}>客户付款信息</div>
              <div style={cStyle}>
                <div style={tStyle1}>凭证信息</div>
                <div type="flex">
                  <div style={{ marginTop: 5 }}>
                    {OrderinformationList.picUrl
                      ? OrderinformationList.picUrl.split(",").map((img, index) => {
                        return (
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              display: "inline-block",
                              marginLeft: "20px",
                            }}
                            key={index}
                            onClick={() => {
                              fullScrImg({
                                url: OrderinformationList.picUrl.split(","),
                                index,
                              });
                            }}
                          >
                            <img
                              src={img}
                              style={{
                                width: "100px",
                                height: "100px",
                              }}
                            />
                          </div>
                        );
                      })
                      : "暂无"}
                  </div>
                </div>
              </div>
              <div style={cStyle}>
                <div style={tStyle1}>订单备注</div>
                {OrderinformationList.brokerMemo ? (
                  <div>
                    <div>
                      <h3>{OrderinformationList.brokerMemo} </h3>
                      <p>
                        {" "}
                        {OrderinformationList.businessConfirmTime.replace("T", " ")}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {OrderinformationList.operName}
                      </p>
                    </div>
                  </div>
                ) : (
                  "暂无"
                )}
              </div>
            </div>
          </div>
          {userInvoiceInfo.invoiceType == "0" ? (
            <div style={cStyle}>
              <div style={cStyle}>
                <div style={tStyle}>发票基本信息</div>
                <Row style={dStyle}>
                  <Col span={8}>
                    发票号：
                    {userInvoiceInfo.invoiceNo || "暂无"}
                  </Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={8}>
                    发票类型：
                    {userInvoiceInfo.invoiceType == "0" ? "企业增值税专用发票" : "暂无"}
                  </Col>
                  <Col span={8}>
                    发票抬头：
                    {userInvoiceInfo.invoiceTitle || "暂无"}
                  </Col>
                  <Col span={8}>
                    纳税人识别号：
                    {userInvoiceInfo.taxNo || "暂无"}
                  </Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={8}>
                    发票金额：
                    {userInvoiceInfo.totalAmt ? userInvoiceInfo.totalAmt + "元" : "暂无"}
                  </Col>
                  <Col span={8}>
                    发票内容：
                    {sendJson.invoiceContent || userInvoiceInfo.invoiceContent || "暂无"}
                  </Col>
                  <Col span={8}>
                    申请日期：
                    {userInvoiceInfo.createTime ? userInvoiceInfo.createTime.replaceAll("T", " ") : "暂无"}
                  </Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={8}>
                    开票日期：
                    {userInvoiceInfo.invoiceTime ? userInvoiceInfo.invoiceTime.replaceAll("T", " ") : "暂无"}
                  </Col>
                  <Col span={8}>
                    企业注册电话：
                    {sendJson.tel || "暂无"}
                  </Col>
                  <Col span={8}>
                    开户行名称：
                    {sendJson.bank || "暂无"}
                  </Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={8}>
                    银行账号：
                    {sendJson.bankAccount || "暂无"}
                  </Col>
                  <Col span={8}>
                    企业注册地址：
                    {sendJson.address || "暂无"}
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            ""
          )}
          {userInvoiceInfo.invoiceType == "51" ? (
            <div style={cStyle}>
              <div style={cStyle}>
                <div style={tStyle}>发票基本信息</div>
                <Row style={dStyle}>
                  <Col span={8}>
                    发票号：
                    {userInvoiceInfo.invoiceNo || "暂无"}
                  </Col>
                  <Col span={8}>
                    发票类型：
                    {userInvoiceInfo.invoiceType == "51" ? "增值税电子普通发票" : "暂无"}
                  </Col>
                  <Col span={8}>
                    抬头类型：
                    {userInvoiceInfo.invoiceTitleType == "0" ? "企业" : userInvoiceInfo.invoiceTitleType == "1" ? "个人" : "暂无"}
                  </Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={8}>
                    发票抬头：
                    {userInvoiceInfo.invoiceTitle || "暂无"}
                  </Col>
                  <Col span={8}>税号：{userInvoiceInfo.taxNo || "暂无"}</Col>
                  <Col span={8}>
                    发票金额：
                    {userInvoiceInfo.totalAmt ? userInvoiceInfo.totalAmt + "元" : "暂无"}
                  </Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={8}>
                    发票内容：
                    {userInvoiceInfo.invoiceContent || "暂无"}
                  </Col>
                  <Col span={8}>
                    申请日期：
                    {userInvoiceInfo.createTime ? userInvoiceInfo.createTime.replaceAll("T", " ") : "暂无"}
                  </Col>
                  <Col span={8}>
                    开票日期：
                    {userInvoiceInfo.invoiceTime ? userInvoiceInfo.invoiceTime.replaceAll("T", " ") : "暂无"}
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            ""
          )}
          <div style={cStyle}>
            <div style={tStyle}>异常处理信息</div>
            <Table
              dataSource={legalCloudOperHis}
              columns={[
                {
                  title: "ID",
                  dataIndex: "id",
                },
                {
                  title: "异常情况",
                  dataIndex: "operContent",
                },
                {
                  title: "操作人",
                  dataIndex: "operName",
                },
                {
                  title: "操作时间",
                  dataIndex: "operDate",
                  render: (t, r) => filter.date(t) + " " + filter.time(r.operTime),
                },
                popoverTable(40, {
                  title: "备注",
                  dataIndex: "memo",
                }),
              ]}
              size="middle"
              pagination={
                legalCloudOperHis.length > 10
                  ? {
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                  }
                  : false
              }
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>操作记录</div>
            <Tables code={5} detailCustId={this.props.match.params.id} ref={this.TablesRef} />
          </div>
        </div>
      </div>
    );
  }
}

let CustomerDetails = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(CustomerDetailsM);
export default CustomerDetails;
let Timer;
class FapiaoM extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      confirmLoading: false,
      custList: [],
      isTrue: true,
    };
  }

  componentWillMount () { }

  // 单击确定按钮提交表单
  handleSubmit = e => {
    this.formRef.current
      .validateFields()
      .then(async values => {
        console.log("发送的数据，==", values, window.brokerId);
        values.totalAmt = this.props.Info.payAmt;
        values.orderIds = [this.props.Info.orderId];
        values.linkman = this.props.Info.linkman;
        values.mobileNo = this.props.Info.mobileNo;
        values.userId = this.props.Info.currentUserId;
        values.address = this.props.Info.province + "" + this.props.Info.city;
        try {
          this.setState({ confirmLoading: true });
          let res = await Axios("put", "/order/invoice", values);
          if (res.code === "00") {
            console.log(res.msg);
            message.success(res.message || res.errorMsg);
            this.props.getDet();
            this.setState({
              visible: false,
              confirmLoading: false,
            });
            this.formRef.current.resetFields();
          } else {
            message.error(res.message);
          }
        } catch (err) {
          message.error(err.message);
          this.setState({ confirmLoading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true }, () => {
      this.formRef.current.setFieldsValue({
        invoiceTitle: (this.props.Info || {}).companyName,
      });
      this.GetCreditCode();
    });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };

  getCustList = async v => {
    if (Timer) {
      clearTimeout(Timer);
    }
    Timer = setTimeout(async () => {
      try {
        let res = await Axios("get", "/custInfos/open/Eci/simple/v2", { companyName: v }, "fireflyErp");
        console.log("企业列表=", res);
        if (res.isSuccess == "T" && res.body) {
          let custList = [];
          JSON.parse(res.body).result.forEach(item => {
            if (item.name) {
              let names = [];
              for (let i = 0; i < item.name.length; i++) {
                let yes = false;
                for (let j = 0; j < v.length; j++) {
                  if (v[j] == item.name[i]) {
                    yes = true;
                    break;
                  }
                }
                if (yes) {
                  names.push(<span style={{ color: "#FD485E" }}>{item.name[i]}</span>);
                } else {
                  names.push(item.name[i]);
                }
              }
              custList.push(
                <Option key={item.keyNo} value={item.name}>
                  {names}
                </Option>
              );
            }
          });
          this.setState({
            custList,
          });
        } else {
          this.setState({
            custList: [],
          });
        }
      } catch (err) {
        if (err && err.message) {
          message.error(err.message);
        }
      }
    }, 700);
  };
  searchCust = async value => {
    try {
      let res = await Axios("get", `/order/creditCode`, {
        companyName: value.name,
        keyNo: value.keyNo,
      });
      if (res.isSuccess == "T") {
        let obj = res.body;
        this.formRef.current.setFieldsValue({
          taxNo: obj.creditCode || undefined,
        });
      } else {
      }
    } catch (err) { }
  };
  GetCreditCode = async () => {
    try {
      let res = await Axios(
        "get",
        `/eci/creditCode`,
        {
          companyName: (this.props.Info || {}).companyName,
        },
        "fireflyErp"
      );
      if (res.isSuccess == "T") {
        this.searchCust(res.body);
      } else {
      }
    } catch (err) { }
  };
  render () {
    const { Info } = this.props;
    return (
      <span>
        <span onClick={this.showModal}>{this.props.children}</span>
        <Modal confirmLoading={this.state.loading} title="发票开具" open={this.state.visible} onOk={this.handleSubmit} onCancel={this.handleCancel}>
          <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} ref={this.formRef}>
            <p style={{ paddingLeft: "10%", color: "red" }}>
              请认真审核发票抬头、纳税人识别号等信息，确保准确无误。
              <br /> 发票开错，无法重新开具！请仔细核对
            </p>
            <Form.Item
              label="发票类型"
              name="invoiceType"
              initialValue={"51"}
              rules={[
                {
                  required: false,
                  message: "发票类型不能为空",
                },
              ]}
            >
              <Select placeholder="请选择状态" disabled>
                <Option key="0" value="0">
                  专用发票
                </Option>
                <Option key="51" value="51">
                  增值税电子普通发票
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="抬头类型"
              name="invoiceTitleType"
              initialValue={"0"}
              rules={[
                {
                  required: true,
                  message: "抬头类型不能为空",
                },
              ]}
            >
              <Radio.Group
                onChange={e => {
                  this.formRef.current.setFieldsValue({
                    // invoiceTitle: undefined,
                    // companyName: undefined,
                    // taxNo: undefined,
                  });
                  this.setState({ isTrue: e.target.value == 0 });
                }}
                defaultValue={"0"}
              >
                <Radio value="1" key="1">
                  个人或事业单位
                </Radio>
                <Radio value="0" key="0">
                  企业
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="发票抬头"
              name="invoiceTitle"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "请填写抬头名称",
                },
              ]}
            >
              <Input placeholder="请输入客户名称" type="text" disabled />
              {/* <Select
                showSearch
                optionLabelProp="value"
                placeholder="请输入客户名称"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={v => {
                  setTimeout(() => {
                    if (v) {
                      v = v.replace(/\s/g, "");
                      this.formRef.current.setFieldsValue({
                        companyName: v,
                        invoiceTitle: !this.state.isTrue ? v : undefined,
                      });
                      this.getCustList(v);
                    }
                  });
                }}
                notFoundContent={null}
                onSelect={(v, options) => {
                  if (v) {
                    v = v.replace(/\s/g, "");
                  }
                  if (v) {
                    this.searchCust(options);
                  }
                }}
              >
                {this.state.custList}
              </Select> */}
            </Form.Item>
            {this.state.isTrue ? (
              <Form.Item
                label="税号"
                name="taxNo"
                initialValue={undefined}
                rules={[
                  {
                    required: true,
                    message: "请填写税号",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="填写纳税人税号" type="text" />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item
              label="发票内容"
              name="invoiceContent"
              initialValue={"技术服务费"}
              rules={[
                {
                  required: false,
                  message: "技术服务费不能为空",
                },
              ]}
            >
              <Input placeholder="请输入" type="text" disabled />
            </Form.Item>
            <Form.Item label="总金额">
              <span style={{ color: "red" }}>{Info.payAmt}</span>元
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let Fapiao = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(FapiaoM);

class ZhuanFapiaoM extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      confirmLoading: false,
    };
  }

  componentWillMount () { }

  // 单击确定按钮提交表单
  handleSubmit = e => {
    e.preventDefault();
    this.formRef.current
      .validateFields()
      .then(async values => {
        values.totalAmt = this.props.Info.payAmt;
        values.orderIds = [this.props.Info.orderId];
        values.linkman = this.props.Info.linkman;
        values.mobileNo = this.props.Info.mobileNo;
        values.userId = this.props.Info.currentUserId;
        values.invoiceContent = "服务费";

        console.log("发送的数据，==", values, window.brokerId);
        try {
          this.setState({ confirmLoading: true });
          let res = await Axios("put", "/order/invoice", values);
          if (res.code === "00") {
            console.log(res.msg);
            this.props.getDet();
            this.setState({
              visible: false,
              confirmLoading: false,
            });
            this.formRef.current.resetFields();
          } else {
            message.info(res.message);
          }
        } catch (err) {
          message.error(err.message);
          this.setState({ confirmLoading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };
  searchCust = async companyId => {
    try {
      let res = await Axios("get", `/order/creditCode`, {
        companyName: companyId.props.value,
        keyNo: companyId.key,
      });
      console.log(res, "resresresresresresresres");
      if (res.isSuccess == "T") {
        let obj = res.body;
        this.formRef.current.setFieldsValue({
          bankAccount: obj.bankAccount || undefined,
          bank: obj.bank || undefined,
          tel: obj.tel || undefined,
          address: obj.address || undefined,
          taxNo: obj.creditCode || undefined,
        });
      } else {
      }
    } catch (err) { }
  };
  getCustList = async v => {
    if (Timer) {
      clearTimeout(Timer);
    }
    Timer = setTimeout(async () => {
      try {
        let res = await Axios("get", "/custInfos/open/Eci/simple/v2", { companyName: v }, "fireflyErp");
        console.log("企业列表=", res);
        if (res.isSuccess == "T" && res.body) {
          let custList = [];
          JSON.parse(res.body).result.forEach(item => {
            if (item.name) {
              let names = [];
              for (let i = 0; i < item.name.length; i++) {
                let yes = false;
                for (let j = 0; j < v.length; j++) {
                  if (v[j] == item.name[i]) {
                    yes = true;
                    break;
                  }
                }
                if (yes) {
                  names.push(<span style={{ color: "#FD485E" }}>{item.name[i]}</span>);
                } else {
                  names.push(item.name[i]);
                }
              }
              custList.push(
                <Option key={item.keyNo} value={item.name}>
                  {names}
                </Option>
              );
            }
          });
          this.setState({
            custList,
          });
        } else {
          this.setState({
            custList: [],
          });
        }
      } catch (err) {
        if (err && err.message) {
          message.error(err.message);
        }
      }
    }, 700);
  };
  render () {
    console.log(this.formRef);
    const { Info } = this.props;
    return (
      <span>
        <span onClick={this.showModal}>{this.props.children}</span>
        <Modal confirmLoading={this.state.loading} title="发票开具" open={this.state.visible} onOk={this.handleSubmit} onCancel={this.handleCancel}>
          <Form ref={this.formRef} layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <p style={{ paddingLeft: "10%", color: "red" }}>
              请认真审核发票抬头、纳税人识别号等信息，确保准确无误。
              <br /> 发票开错，无法重新开具！请仔细核对
            </p>
            <Form.Item
              label="发票类型"
              name="invoiceType"
              initialValue={"0"}
              rules={[
                {
                  required: false,
                  message: "发票类型不能为空",
                },
              ]}
            >
              <Select placeholder="请选择状态" disabled>
                <Option key="0" value="0">
                  企业增值税专用发票
                </Option>
                <Option key="51" value="51">
                  增值税电子普通发票
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="抬头类型"
              name="invoiceTitleType"
              initialValue={"0"}
              rules={[
                {
                  required: false,
                  message: "发票类型不能为空",
                },
              ]}
            >
              <Select placeholder="请选择状态" disabled>
                <Option key="0" value="0">
                  企业
                </Option>
                <Option key="51" value="51">
                  增值税电子普通发票
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="发票抬头"
              name="invoiceTitle"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "请填写抬头名称",
                },
              ]}
            >
              <Select
                showSearch
                optionLabelProp="value"
                placeholder="请输入客户名称"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={v => {
                  setTimeout(() => {
                    if (v) {
                      v = v.replace(/\s/g, "");
                      console.log(this.formRef.current);
                      this.formRef.current.setFieldsValue({
                        companyName: v,
                      });
                      this.getCustList(v);
                    }
                  });
                }}
                notFoundContent={null}
                onSelect={(v, options) => {
                  if (v) {
                    v = v.replace(/\s/g, "");
                  }
                  if (v) {
                    this.searchCust(options);
                  }
                }}
              >
                {this.state.custList}
              </Select>
            </Form.Item>
            <Form.Item
              label="纳税人识别号"
              name="taxNo"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "请填写纳税人识别号",
                },
              ]}
            >
              <Input placeholder="填写纳税人识别号" type="text" />
            </Form.Item>
            <Form.Item
              label="企业注册地址"
              name="address"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "请填写企业注册地址",
                },
              ]}
            >
              <Input placeholder="填写企业注册地址" type="text" />
            </Form.Item>
            <Form.Item
              label="企业注册电话"
              name="tel"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "请填写企业注册电话",
                },
              ]}
            >
              <Input placeholder="填写企业注册电话" type="text" />
            </Form.Item>
            <Form.Item
              label="开户行名称"
              name="bank"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "请填写开户行名称",
                },
              ]}
            >
              <Input placeholder="填写开户行名称" type="text" />
            </Form.Item>
            <Form.Item
              label="银行账户"
              name="bankAccount"
              initialValue={undefined}
              rules={[
                {
                  required: true,
                  message: "请填写银行账户",
                },
              ]}
            >
              <Input placeholder="填写银行账户" type="text" />
            </Form.Item>
            <Form.Item label="总金额">
              <span style={{ color: "red" }}>{Info.payAmt}</span>元
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let ZhuanFapiao = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(ZhuanFapiaoM);

class ContractM extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      confirmLoading: false,
      productList: [],
      priceData: {
        legal_basic: [8800, 9800, 10800],
        legal_honour: [19800, 21800, 23800],
        legal_normal: [3980],
        legal_honour_pro: [33800],
      },
    };
  }

  componentWillMount () { }
  onConfirm = () => {
    confirm({
      width: 450,
      title: "暂不支持线上申请 [定制版] 云法务套餐电子合同",
      icon: <InfoCircleFilled style={{ color: "#1775FF" }} />,
      content: "[定制版] 云法务套餐属于一案一议，请向云法务运维经理申请纸质合同或电子合同",
      maskClosable: true,
      onOk () { },
      okText: "确定",
      onCancel () { },
    });
  };
  // 单击确定按钮提交表单
  handleSubmit = e => {
    const { userRole } = this.props.operInfo;
    e.preventDefault();
    let arr = ["address", "contractName", "custName", "email", "mobileNo", "orderId", "price", "specification", "startDate", "linkman"];
    this.formRef.current
      .validateFields(arr)
      .then(async values => {
        let sendData = values;
        if (sendData.startDate) {
          sendData.startDate = moment(sendData.startDate).format("YYYY-MM-DD");
        }
        sendData.term = sendData.specification[1].split("-")[2];
        // sendData.price = sendData.specification[1].split("-")[0];
        sendData.specification = sendData.specification[1].split("-")[1];
        if (sendData.price) {
          sendData.price = sendData.price * 1;
        }
        if (sendData.specification && sendData.specification.includes("legal_customized")) {
          this.onConfirm();
          return;
        }
        try {
          this.setState({ confirmLoading: true });
          let res = await Axios("post", "/esign", sendData);
          if (res.code == "200" || res.code == "00") {
            this.props.refreshTable();
            this.formRef.current.resetFields();
            message.success("提交成功，请通知客户签章。");
            this.setState({
              visible: false,
              confirmLoading: false,
            });
          } else {
            message.error(res.message);
            this.setState({ confirmLoading: false });
          }
        } catch (err) {
          message.error(err.message);
          this.setState({ confirmLoading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
    Axios("get", "/order/product/group", {
      allSpec: true, //传true任何情况都返回SaaS版
    }).then(val => {
      console.log(val.data);
      val.data.map(v => {
        v.name = v.name;
        v.specification = v.name;
        v.winCoinProductVOS.map(i => {
          i.name = `${i.productDetail} ${i.costAmt}元·${i.serviceMode}`;
          i.specification = i.costAmt + "-" + i.specification + "-" + i.serviceMode;
        });
        v.children = v.winCoinProductVOS;
      });
      this.setState({
        productList: val.data,
      });
    });
  };
  onChangePackage = e => {
    if (e) {
      this.formRef.current.setFieldsValue({ price: e[1].split("-")[0] });
    }
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };
  render () {
    const { userRole } = this.props.operInfo;
    const { type, InformationList } = this.props;
    const { productList, priceData } = this.state;
    return (
      <span>
        <Button type="primary" icon={<PlusOutlined />} style={{ float: "right" }} onClick={this.showModal} disabled={["0", "2"].includes(String(InformationList.status)) || !InformationList.isCanSign}>
          新增电子合同
        </Button>

        <Modal
          title="新增电子合同"
          open={this.state.visible}
          onOk={this.handleSubmit}
          confirmLoading={this.state.confirmLoading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        >
          <Form ref={this.formRef} layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              label="订单ID"
              name="orderId"
              initialValue={window.brokerId}
              rules={[
                {
                  required: true,
                  message: "请输入订单ID",
                },
              ]}
            >
              <Input disabled placeholder="请输入订单ID" type="text" style={{ width: "100%" }} />
            </Form.Item>{" "}
            <Form.Item
              label="合同名称"
              name="contractName"
              initialValue="云法务服务合同"
              rules={[
                {
                  required: true,
                  message: "请输入合同名称",
                },
                {
                  pattern: /^((?!\s*$))/,
                  message: "请输入合同名称",
                },
              ]}
            >
              <Input maxLength={40} placeholder="请输入合同名称" type="text" style={{ width: "100%" }} />
            </Form.Item>{" "}
            <Form.Item
              label="客户名称"
              name="custName"
              initialValue={InformationList.companyName || undefined}
              rules={[
                {
                  required: true,
                  message: "请输入客户名称",
                },
                {
                  pattern: /^((?!\s*$))/,
                  message: "请输入客户名称",
                },
              ]}
            >
              <Input disabled maxLength={40} placeholder="请输入客户名称" type="text" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="联系地址"
              name="address"
              // initialValue={InformationList.province &&
              //     InformationList.province
              //     ? InformationList.province +
              //     "" +
              //     InformationList.city
              //     : undefined}
              rules={[
                {
                  required: true,
                  message: "请输入联系地址",
                  whitespace: true,
                },
              ]}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 2 }} maxLength={40} placeholder="请输入联系地址" type="text" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="联系人"
              name="linkman"
              initialValue={InformationList.linkman || undefined}
              rules={[
                {
                  required: true,
                  message: "请输入联系人",
                  whitespace: true,
                },
              ]}
            >
              <Input disabled maxLength={40} placeholder="请输入联系人" type="text" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="联系电话"
              name="mobileNo"
              initialValue={InformationList.mobileNo || undefined}
              rules={[
                {
                  required: true,
                  message: "联系电话不能为空",
                },
                {
                  message: "联系电话不合法",
                  pattern: /^((1[0-9]{2})+\d{8})$/,
                },
              ]}
            >
              <InputNumber disabled style={{ width: "100%" }} maxLength={11} placeholder="请输入联系电话" />
            </Form.Item>
            <Form.Item
              label="套餐"
              style={{ width: "100%" }}
              name="specification"
              initialValue={Filter.legalspeciName(InformationList.specification, InformationList.costAmt)}
              rules={[
                {
                  required: true,
                  message: "请选择套餐",
                },
              ]}
            >
              <Cascader
                fieldNames={{
                  label: "name",
                  value: "specification",
                  children: "children",
                }}
                options={productList}
                disabled
                onChange={this.onChangePackage}
                placeholder="请选择"
              />
            </Form.Item>
            <Form.Item
              label="价格"
              style={{ width: "100%" }}
              name="price"
              initialValue={InformationList.costAmt || undefined}
              rules={[
                {
                  required: true,
                  message: "请输入价格",
                },
              ]}
            >
              <Input addonAfter="元" disabled style={{ width: "100%" }} maxLength={11} placeholder="请输入价格" />
            </Form.Item>
            <Form.Item
              label="合作日期："
              name="startDate"
              initialValue={moment(moment())}
              rules={[
                {
                  required: true,
                  message: "合作日期不能为空",
                },
              ]}
            >
              <DatePicker placeholder="请选择合作日期" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="邮箱"
              name="email"
              rules={[
                {
                  required: false,
                  message: "邮箱不能为空",
                },
                {
                  message: "邮箱格式不合法",
                  pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder="请输入邮箱" />
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let Contract = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(ContractM);
class ContractButtonM extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      ContractList: [],
      confirmLoading: false,
      SpinLoading: false,
    };
  }

  componentDidMount () { }
  GetContract = async () => {
    this.setState({ SpinLoading: true });
    const { InformationList } = this.props;
    await Axios("get", "/esign/page", {
      custName: InformationList.companyName,
      isRelation: "Y",
      pageNum: 1,
      pageSize: 10,
    }).then(val => {
      this.setState({
        ContractList: (val.data || {}).dataList || [],
      });
    });
    this.formRef.current.resetFields();
    this.setState({ SpinLoading: false });
  };
  // 单击确定按钮提交表单
  handleSubmit = e => {
    const { InformationList } = this.props;
    const { ContractList } = this.state;
    if (!(ContractList || []).length) {
      this.setState({ visible: false });
      return;
    } else {
      this.formRef.current
        .validateFields()
        .then(values => {
          confirm({
            title: "关联电子合同成功后，不可再次关联，请谨慎操作",
            icon: <ExclamationCircleOutlined />,
            content: null,
            maskClosable: true,
            onOk: async () => {
              try {
                this.setState({ confirmLoading: true });
                let res = await Axios("post", "/esign/relation", { id: ((values || {}).Contract || {}).id, orderId: InformationList.orderId });
                if (res.code == "200" || res.code == "00") {
                  this.props.refreshTable();
                  this.formRef.current.resetFields();
                  message.success("提交成功，请通知客户签章。");
                  this.setState({
                    visible: false,
                    confirmLoading: false,
                  });
                } else {
                  message.error(res.message);
                  this.setState({ confirmLoading: false });
                }
              } catch (err) {
                message.error(err.message);
                this.setState({ confirmLoading: false });
              }
            },
            onCancel () {
              console.log("Cancel");
            },
          });
        })
        .catch(info => {
          console.log("Validate Failed:", info);
        });
    }
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };
  render () {
    const { InformationList } = this.props;
    const { SpinLoading, ContractList } = this.state;
    return (
      <span style={{ marginRight: "12px" }}>
        <Button
          type="primary"
          disabled={["0", "2"].includes(String(InformationList.status)) || !InformationList.isCanSign}
          onClick={() => {
            this.setState({ visible: true });
            this.GetContract();
          }}
        >
          {" "}
          关联已有电子合同
        </Button>
        <Modal
          title="关联已有电子合同"
          open={this.state.visible}
          onOk={this.handleSubmit}
          width={620}
          confirmLoading={this.state.confirmLoading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          className="ContractModal"
        >
          <Form ref={this.formRef}>
            {((ContractList || []).length && <p style={{ fontSize: "14px", color: "#D9001B", marginTop: "12px" }}>我们检测到您该企业下有其他未关联的电子合同，请检查是否同一电子合同，具体的电子合同如下：</p>) || ""}
            <Spin spinning={SpinLoading} size="small">
              {(ContractList || []).length ? (
                <Form.Item
                  label={null}
                  name="Contract"
                  rules={[
                    {
                      required: true,
                      message: "请先选择要关联的合同",
                    },
                  ]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    {ContractList.map(item => (
                      <Radio value={item || {}}>
                        <Row style={{ width: "100%" }}>
                          <Col span={12}>
                            <span style={{ fontWeight: "700" }}>{item.custName || "-"}</span>
                            <br />
                            <span>合同名称：{item.contractName || "-"}</span>
                            <br />
                            <span>套餐：{Filter.legalspecification(item.specification)}</span>
                            <br />
                            <span>申请人：{item.applyName || "-"}</span>
                          </Col>
                          <Col span={12} style={{ width: "316px" }}>
                            <a href={"/#/ElectronicContractDetail/" + item.id} target="_blank">
                              查看合同详情{">"}
                            </a>
                            <br />
                            <span>合同时间：{item.createdTime || "-"}</span>
                            <br />
                            <span>套餐价格：{item.price ? item.price + "元" : "-"}</span>
                            <br />
                            <span>合同状态：{Filter.ElectroniCesignStatus(item.esignStatus)}</span>
                          </Col>
                        </Row>
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              ) : (
                !SpinLoading && <Empty />
              )}
            </Spin>
          </Form>
        </Modal>
      </span>
    );
  }
}

let ContractButton = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(ContractButtonM);
class AddBrokerM extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      Isvisible: false,
      selectOption: [],
      productList: [],
      StatusList: [],
      loading: false,
      confirmLoading: false,
      province: "",
      city: "",
      qingkong: false,
      picUrl: "",
      btnType: "",
      uploadVisible1: false,
    };
  }

  componentWillMount () { }

  // 单击确定按钮提交表单
  handleSubmit = e => {
    const { userRole } = this.props.operInfo;

    e.preventDefault();
    this.formRef.current
      .validateFields()
      .then(async values => {
        let sendData = values;
        if (this.state.picUrl) {
          sendData.picUrl = this.state.picUrl;
        } else {
          message.info("请上传凭证");
          return;
        }
        sendData.orderId = window.brokerId;

        try {
          this.setState({ confirmLoading: true });
          let res = await Axios("post", "/order/upload/voucher", sendData);
          if (res.code == "200" || res.code == "00") {
            this.props.getDet();
            this.formRef.current.resetFields();
            message.success(res.msg);
            this.setState(
              {
                visible: false,
                qingkong: true,
                confirmLoading: false,
                picUrl: "",
              },
              () => {
                this.setState({
                  qingkong: false,
                });
              }
            );
          } else {
            message.error(res.message);
            this.setState({ confirmLoading: false });
          }
        } catch (err) {
          message.error(err.message);
          this.setState({ confirmLoading: false });
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };
  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ Isvisible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };
  render () {
    const { userRole } = this.props.operInfo;
    const { productList, Isvisible, picUrl } = this.state;
    const { OrderinformationList, isHaveEsign } = this.props;

    return (
      <span>
        <Button
          type="primary"
          style={{ float: "right", marginRight: "12px" }}
          disabled={OrderinformationList.offlinePayStatus == 2}
          onClick={() => {
            if (isHaveEsign || OrderinformationList.orderType === "71") {
              let type = "add",
                picUrl = "";
              OrderinformationList.businessConfirmTime && (type = "edit");
              OrderinformationList.businessConfirmTime && (picUrl = this.props.OrderinformationList.picUrl || "");
              this.setState({ visible: true, btnType: type, picUrl }, () => {
                this.setState({ uploadVisible1: true });
              });
            } else {
              this.setState({ Isvisible: true });
            }
          }}
        >
          {OrderinformationList.offlinePayStatus == 2 ? "已确认客户付款" : !OrderinformationList.businessConfirmTime ? "确认客户付款" : "修改客户付款凭证"}
        </Button>
        <Modal
          width={475}
          title={<h3 style={{ margin: 0 }}>温馨提示</h3>}
          open={Isvisible}
          footer={
            <Button type="primary" onClick={this.handleCancel}>
              确定
            </Button>
          }
          onCancel={this.handleCancel}
        >
          <h3>因业务合规性要求，请先上传该客户的线下签约合同或添加关联电子合同后，方可操作“确认客户付款”</h3>
          <p style={{ margin: "12px 0 48px", color: "#919498", fontSize: 14 }}>*如客户已使用电子合同签约，可主动催促尽快完成电子合同签约流程</p>
        </Modal>
        <Modal
          title={this.state.btnType == "add" ? "确认客户付款" : "修改客户付款凭证"}
          open={this.state.visible}
          onOk={this.handleSubmit}
          confirmLoading={this.state.confirmLoading}
          width={530}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        >
          <Form ref={this.formRef} layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <div
              style={{
                marginBottom: 20,
                marginLeft: 10,
                color: "rgba(0, 0, 0, 0.85)",
                fontWeight: 600,
              }}
            >
              套餐支付金额：{OrderinformationList.costAmt}元<span style={{ color: "#f44336" }}>（如果与实际支付金额不符，请去修改套餐金额）</span>
            </div>
            <div
              type="flex"
              style={{
                marginBottom: "20px",
                color: "rgba(0, 0, 0, 0.85)",
                marginLeft: 10,
              }}
            >
              上传凭证：
              {this.state.uploadVisible1 ? (
                <Ossupload
                  callbackData={{ test: "123" }}
                  callback={async (filename, file, info, callbackData) => {
                    if (info.status == 200) {
                      try {
                        let picUrl = "";
                        if (this.state.picUrl) {
                          picUrl = this.state.picUrl + "," + window.winhc_oss_url + filename;
                        } else {
                          picUrl = window.winhc_oss_url + filename;
                        }

                        let send = {
                          orderId: window.brokerId,
                          picUrl: picUrl,
                        };
                        this.setState({
                          picUrl: picUrl,
                        });
                      } catch (err) {
                        console.log("err", err);
                        message.error(err.msg);
                      }
                    }
                  }}
                  beforeUpload={(file, up) => {
                    console.log(file, up);
                    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "image/gif";
                    if (!isJpgOrPng) {
                      message.error("请上传格式为jpg，png，gif的照片");
                      return false;
                    }

                    if (file.size > 1024 * 1024 * 5) {
                      message.error("凭证信息超过限制范围，请重新上传");
                      return false;
                    }
                  }}
                >
                  <Button
                    disabled={this.state.picUrl.split(",").length >= 10}
                    type="primary"
                    style={{
                      marginTop: "-20px",
                      marginRight: "20px",
                    }}
                  >
                    上传
                  </Button>
                </Ossupload>
              ) : (
                ""
              )}
              <p
                style={{
                  marginTop: 5,
                  fontSize: "12px",
                  color: "#bdbbbb",
                }}
              >
                支持png，jpg，gif等格式。大小不超过5M；请谨慎操作，一旦提交客户付款信息后，不可修改
              </p>
              <div style={{ marginTop: 5 }}>
                {this.state.picUrl
                  ? this.state.picUrl.split(",").map((img, index) => {
                    return (
                      <div key={index} className={this.props.hoverBg ? "hoverfff fileList" : "fileList"}>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            display: "inline-block",
                            marginTop: "10px",
                          }}
                          key={index}
                          onClick={() => {
                            fullScrImg({
                              url: this.state.picUrl.split(","),
                              index,
                            });
                          }}
                        >
                          <img
                            src={img}
                            style={{
                              width: "100px",
                              height: "100px",
                            }}
                          />
                        </div>
                        <span>
                          <div className="close">
                            <CloseOutlined
                              onClick={() => {
                                let Src = "";
                                picUrl.split(",").forEach((v, i) => {
                                  if (i != index) {
                                    if (Src) {
                                      Src = Src + "," + v;
                                    } else {
                                      Src = v;
                                    }
                                  }
                                });
                                console.log(Src);
                                this.setState({
                                  picUrl: Src,
                                });
                              }}
                            />
                          </div>
                        </span>
                      </div>
                    );
                  })
                  : ""}
              </div>
            </div>
            <Form.Item
              label="订单备注："
              name="brokerMemo"
              rules={[
                {
                  required: false,
                  message: "订单备注不能为空",
                },
              ]}
            >
              <TextArea rows={4} maxLength={300} placeholder="请输入内容" style={{ width: "80%" }} />
            </Form.Item>
          </Form>
        </Modal>
      </span>
    );
  }
}

let AddBroker = connect(
  state => {
    return state;
  },
  dispatch => ({
    change_operInfo: bindActionCreators(change_operInfo, dispatch),
  })
)(AddBrokerM);
