import React, { Component } from "react";
import {
  Row,
  Breadcrumb,
  Button,
  Form,
  message,
  Modal,
  Input,
  Select,
  Col,
  Radio,
  Popover,
  Table,
  TreeSelect,
  Alert,
  DatePicker,
  InputNumber,
  Cascader,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import filter from "../../common/filter";
import moment from "moment";
import Login from "../login/login";
import { change_operInfo } from "../../redux/actions/operInfo";
import { bindActionCreators } from "redux";

const { Option } = Select;
let sendBody = {};
const { RangePicker } = DatePicker;

class RelevanceOrderQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      currentPage:
        window.routerCache.RelevanceOrderQuery.currentPage || 1,
    };
    sendBody = window.routerCache.RelevanceOrderQuery.sendBody || {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState({
      tData: data,
      totalNum: totalNum,
      currentPage: currentPage,
    });
  };
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });

      let send = sendBody;
      // send.workType = window.routerCache.OrderQuery.send_workType || '';
      // sendBody.status = window.routerCache.OrderQuery.workType_status || '';
      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      let res = await Axios("get", "/order/relation/list", send);
      this.setState({
        loading: false,
        tData: res.data.dataList,
        totalNum: res.data.totalNum,
      });
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };

  handleTableChange = async (pagination) => {
    this.setState({ loading: true });
    try {
      let send = sendBody;
      send.pageNum = pagination.current + "";
      send.pageSize = "10";
      // send.workType = window.routerCache.OrderQuery.send_workType || '';
      // sendBody.status = window.routerCache.OrderQuery.workType_status || '';
      let res = await Axios("get", "/order/relation/list", send);
      this.setState({
        loading: false,
        tData: res.data.dataList,
        totalNum: res.data.totalNum,
        currentPage: pagination.current,
      });
    } catch (err) {
      // message.error(err.message);
      this.setState({ loading: false });
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    this.refreshTable();
  }

  componentWillUnmount () {
    window.routerCache.RelevanceOrderQuery.currentPage =
      this.state.currentPage;
    window.routerCache.RelevanceOrderQuery.sendBody = sendBody;
  }

  render () {
    // let Quanxian=  this.props.operInfo.operInfo.roles.includes('ROLE_BrokerManage');
    let Quanxian = true;
    let columns = [
      {
        title: "订单ID",
        dataIndex: "orderId",
        fixed: "left",
        // width: 170,
      },
      popoverTable(6, {
        title: "客户名称",
        dataIndex: "companyName",
        // width: 90,
      }),
      popoverTable(6, {
        title: "联系人",
        dataIndex: "linkMan",
        // width: 90,
      }),
      {
        title: "所属地区",
        dataIndex: "province",
        // width: 120,
        render: (t, r) => t + "   " + r.city,
      },
      {
        title: "所属运营商",
        dataIndex: "brokerName",
        // width: 90,
      },
      {
        title: "套餐",
        dataIndex: "specification",
        // width: 80,
        render: (t) => Filter.legalspecification(t),
      },
      {
        title: "金额",
        dataIndex: "transAmt",
        // width: 80,
        render: (text, record) => text + "元",
      },
      {
        title: "购买日期",
        dataIndex: "payTime",
        // width: 150,
        render: (text, record) =>
          text ? text.replace("T", "  ") : "-",
      },
      {
        title: "订单状态",
        dataIndex: "status",
        // width: 150,
        render: (t) => Filter.RelevanceOrderStatus(t),
      },
      {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        // width: 90,
        render: (text, record, index) => (
          <Link to={"/RelevanceOrderQueryDetail/" + record.orderId}>
            查看
          </Link>
        ),
      },
    ];
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>运营商关联订单</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">运营商关联订单</span>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div className="scrollMain">
            <Search refreshTableByData={this.refreshTableByData} />
            <div style={{ backgroundColor: "#fff" }}>
              <Table
                scroll={{ x: 'max-content' }}
                style={{ alignItems: "center" }}
                dataSource={this.state.tData}
                rowKey="orderId"
                columns={columns}
                size="middle"
                pagination={{
                  size: "large",
                  showQuickJumper: true,
                  pageSize: 10,
                  total: parseInt(this.state.totalNum),
                  current: Number(this.state.currentPage),
                  showTotal: (total) => {
                    return "共 " + total + " 条记录 ";
                  },
                }}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return state;
  },
  (dispatch) => ({})
)(RelevanceOrderQuery);

class SearchM extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      province: sendBody.province || "",
      city: sendBody.city || "",
      qingkong: false,
      StatusList: [],
    };
  }

  // 查询
  query = async () => {
    let _this = this;
    sendBody = this.formRef.current.getFieldsValue();
    if (sendBody.date1) {
      sendBody.startPayTime = filter.timetrans(sendBody.date1[0], "day");
      sendBody.endPayTime = filter.timetrans(sendBody.date1[1], "day");
    }
    if (this.state.city) {
      sendBody.city = this.state.city;
    }
    if (this.state.province) {
      sendBody.province = this.state.province;
    }
    delete sendBody.date1;
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    // sendBody.workType = window.routerCache.OrderQuery.send_workType || '';
    // sendBody.status = window.routerCache.OrderQuery.workType_status || '';
    try {
      console.log(sendBody);
      let res = await Axios("get", "/order/relation/list", sendBody);

      _this.props.refreshTableByData(
        res.data.dataList,
        res.data.totalNum,
        1
      );
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearOptions = async () => {
    // 清空的时候清空地区
    this.setState(
      {
        province: "",
        city: "",
        qingkong: true,
      },
      () => {
        this.setState({
          qingkong: false,
        });
        this.formRef.current.resetFields();
      }
    );
    sendBody = {};
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    // sendBody.workType = window.routerCache.OrderQuery.send_workType || '';
    // sendBody.status = window.routerCache.OrderQuery.workType_status || '';

    this.props.refreshTableByData([], 1, 1);
    console.log(sendBody);
    try {
      let res = await Axios("get", "/order/relation/list", sendBody);

      this.props.refreshTableByData(
        res.data.dataList,
        res.data.totalNum,
        1
      );
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearLawfirm = async () => {
    window.routerCache.RelevanceOrderQuery.workTypeStr = "";
    window.routerCache.RelevanceOrderQuery.workType_status = "";
    sendBody.status = "";
    this.props.refreshTableByData([], 1, 1);
    this.setState({
      workType_status: "",
    });
    try {
      let res = await Axios("get", "/BrokerInfos/pageLists", sendBody);
      this.props.refreshTableByData(
        res.data.dataList,
        res.data.totalNum,
        1
      );
    } catch (err) {
      // message.error(err.message);
    }
  };
  // 选择日期范围

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    const { userRole } = this.props.operInfo;
    const job = window.globalData.job;
    if (userRole === "1" || job == "senior") {
      Axios("get", "/brokeruser/page", {}).then((val) => {
        this.setState({
          StatusList: (val.data || {}).dataList || [],
        });
      });
    }
  }

  render () {
    /*控制查询按钮状态*/
    const { userRole } = this.props.operInfo;
    const { StatusList } = this.state;
    const { getFieldDecorator } = this.props;
    return (
      <div className="search_like_antD" style={{ marginTop: "-8px" }}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          ref={this.formRef}
        >
          <Row type="flex" align="middle">
            <Col span={8}>
              <Form.Item
                label="客户名称："
                style={{ width: "100%" }}
                initialValue={sendBody.companyName}
                name="companyName"
              >
                <Input
                  placeholder="请输入客户名称"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="订单ID"
                style={{ width: "100%" }}
                initialValue={sendBody.orderId}
                name="orderId"
              >
                <Input
                  placeholder="请输入订单ID"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="所属地区："
                style={{ width: "100%" }}
                name="province"
              >
                <AreaSelect
                  bodyStyle={{ width: "80%" }}
                  province={this.state.province}
                  city={this.state.city}
                  hiddenCountry
                  Hadoop={false}
                  allowClear
                  qingkong={this.state.qingkong}
                  select={(province, city, county) => {
                    console.log(province, city, county);
                    sendBody.province = province;
                    sendBody.city = city;
                    this.setState({
                      province: province || "",
                      city: city || "",
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={8}>
              <Form.Item
                label="所属运营商"
                style={{ width: "100%" }}
                initialValue={sendBody.userName}
                name="userName"
              >
                <Input
                  placeholder="请输入所属运营商"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<span>订单状态</span>}
                style={{ width: "100%" }}
                initialValue={sendBody.status}
                name="status"
              >
                <Select
                  style={{ width: "80%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value="0">订单完成</Option>
                  <Option value="1">待支付</Option>
                  <Option value="2">支付成功</Option>
                  <Option value="R">需退款</Option>
                  <Option value="D">已删除</Option>
                  <Option value="G">已退款</Option>
                  <Option value="C">已关闭</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="开始购买时间"
                style={{ width: "100%" }}
                initialValue={sendBody.date1}
                name="date1"
              >
                <RangePicker
                  style={{ width: "80%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={8}></Col>

            <Col span={8}></Col>
            <Col span={8}>
              <Row
                type="flex"
                justify="end"
                align="middle"
                style={{ width: "85%", height: "30px" }}
              >
                <Form.Item style={{ marginRight: "16px" }}>
                  <Button type="primary" onClick={this.query}>
                    查询
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={this.clearOptions}>
                    重置
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <div>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={
                window.routerCache.RelevanceOrderQuery
                  .workTypeStr
                  ? {
                    paddingBottom: "10px",
                  }
                  : { paddingBottom: "40px" }
              }
            >
              {window.routerCache.RelevanceOrderQuery
                .workTypeStr ? (
                <Alert
                  message={
                    <div>
                      <span>
                        {
                          window.routerCache
                            .RelevanceOrderQuery
                            .workTypeStr
                        }
                      </span>
                      &nbsp;&nbsp;
                      <a onClick={this.clearLawfirm}>
                        清空
                      </a>
                      &nbsp;&nbsp;
                    </div>
                  }
                  type="info"
                  showIcon
                  style={{ marginTop: "12px" }}
                />
              ) : (
                ""
              )}
            </Row>
          </div>
        </Form>
        <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
      </div>
    );
  }
}

let Search = connect(
  (state) => {
    return state;
  },
  (dispatch) => ({})
)(SearchM);
