import React from "react";
import {
  Button,
  Table,
  Row,
  Col,
  message,
  Form,
  Breadcrumb,
  Tabs,
  Modal,
  Select,
  DatePicker,
  Input,
  Popover,
  Spin
} from "antd";
import { Axios } from "../../axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, HashRouter } from "react-router-dom";
import G2 from "@antv/g2";
import { AreaSelectForm, ImgViewer, rules, FlieList, popoverTable } from "../../common";
import filter from "../../common/filter";
import { Ossupload } from "../../common/upLoad";
import { Shengpi2, Tables, FollowUpRecords, EditModule, EditMobileNo } from "./Common";
import { UploadOutlined } from "@ant-design/icons";

let detailCustId;

class MainM extends React.Component {
  formRef = React.createRef();
  TablesRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      companyInfo: {},
      packageVO: {},
      bizOperHis: [],
      brokeFileList: [],
      visible: false,
      followRecordVOS: [],
      esigns: [],
      legalServiceOrder: {},
      legalCompanyBasicInfo: [],
      wtLoading: false,
      wTvisible: false,
      wTLoading: false,
      CaseData: [],
      CasetotalPage: 0,
      reportList: [],
      MainBodyList: [],
      legalCloudRiskExamRecords: [],
      wtNum: 0,
    };
  }

  componentDidMount () {
    detailCustId = this.props.match.params.id;
    this.getData();
    this.GetMainBody();
    this.getLegalReportData();
    this.getDataList({ current: 1, pageSize: 10 });
  }

  getData = async () => {
    try {
      this.setState({
        loading: true,
      });
      let res = await Axios(
        "get",
        "/brokeruser/company/detail/" + detailCustId,
        {}
      );
      if (res.code === "200") {
        this.setState({
          companyInfo: res.data.companyInfo || {},
          // bizOperHis: res.data.bizOperHis || {},
          packageVO: res.data.packageVO || {},
          legalServiceOrder: res.data.legalServiceOrder || {},
          followRecordVOS: res.data.followRecordVOS || [],
          legalCloudRiskExamRecords: res.data.legalCloudRiskExamRecords || [],
          esigns: res.data.esigns || [],
          legalCompanyBasicInfo: res.data.legalCompanyBasicInfo
            ? [res.data.legalCompanyBasicInfo]
            : [],
        }, () => {
          //主要目的获取案件总数
          this.getCase(1, 1000)
        });
        this.TablesRef.current.getOperhisList();
        if (res.data.legalCloudDocs) {
          this.setState({
            brokeFileList: res.data.legalCloudDocs,
          });
        }
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  GetMainBody = async () => {
    try {
      let res = await Axios("get", "/legalcloud/mainBody/" + detailCustId, {
        pageNum: 1,
        pageSize: 100,
        id: detailCustId,
      });
      if (res.code == "200" || res.isSuccess == "isSuccess") {
        this.setState({ MainBodyList: (res.data || {}).dataList || [] });
      } else {
        message.error(res.errorMsg);
      }
    } catch (err) {
      console.log(err);
    }
  };
  getDataList = async (value) => {
    try {
      this.setState({
        loading: true,
      });
      let res = await Axios(
        "get",
        "/brokeruser/company/detail/" + detailCustId + "/serviceList",
        { pageNum: value.current, pageSize: value.pageSize }
      );
      if (res.code === "200") {
        this.setState({
          bizOperHis: res.data.dataList || [],
        });
      } else {
        message.error(res.message);
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  getCase = async (pageNum, pageSize = 10) => {
    try {
      this.setState({ wTvisible: pageSize === 1000 ? false : true, wTLoading: pageSize === 1000 ? false : true, wtLoading: pageSize === 1000 ? true : false });
      const { companyInfo = {} } = this.state;
      let res = await Axios("get", "/caseBase/page", { creditorName: companyInfo.companyName, isLegalJump: 'Y', pageNum, pageSize });
      if (res.code === "200") {
        this.setState({ CaseData: res.data.dataList, CasetotalPage: res.data.totalNum })
        if (pageSize === 1000) {
          this.setState({ wtNum: res.data.totalNum })
        }
      }
      else {
        message.error(res.message);
      }
      this.setState({ wTLoading: false, wtLoading: false })
    } catch (err) {
      this.setState({ wTLoading: false, wtLoading: false });
    }
  };

  //季度服务报告
  getLegalReportData = async () => {
    try {
      let res = await Axios("get", "/brokeruser/report/" + detailCustId, {});
      if (res.errorCode === "200") {
        this.setState({
          reportList: res.body
        })
      }
      else {
        message.error(res.message);
      }
    } catch (err) {
      //
    }
  };

  addFinalReport = (record) => {
    if (record.reportUrl) {
      window.open(record.reportUrl);
      return
    }
  }
  render () {
    let QuanXian = true;
    const cStyle = {
      padding: "12px 24px 12px 24px",
      marginBottom: "15px",
      borderRadius: "4px",
      background: "#fff",
      fontSize: "14px",
    };
    const tStyle = {
      color: "#222",
      lineHeight: "30px",
      fontWeight: "650",
      fontSize: "16px",
      margin: "0px -24px 15px -24px",
      borderBottom: "1px solid #efe5e5",
      padding: "0 24px 8px 24px",
    };
    const TabsStyle = {
      fontWeight: "650",
      fontSize: "16px",
    };
    const dStyle = {
      color: "#222",
      lineHeight: "30px",
      margin: "10px 0px 0px 0px",
    };
    const {
      companyInfo,
      packageVO,
      followRecordVOS,
      legalServiceOrder,
      esigns,
      wtLoading,
      legalCloudRiskExamRecords,
      MainBodyList,
    } = this.state;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
    };
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to="/PayCompanyManage">
                  付费企业客户管理
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>付费企业客户详情</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">付费企业客户详情</span>
            <Shengpi2
              brokerUserId={companyInfo.brokerUserId}
              refreshTable={this.getData}
              index={"1"}
              detailCustId={detailCustId}
            >
              <Button type="primary">分配业务员</Button>
            </Shengpi2>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div style={cStyle}>
            <div style={tStyle}>企业基本信息</div>
            <Row style={dStyle}>
              <Col span={8}>
                企业名称：{companyInfo.companyName || "暂无"}
                {(MainBodyList || []).length ? (
                  <Popover
                    placement="right"
                    content={
                      <div>
                        {(MainBodyList || []).map((v, i) => (
                          <h3>
                            {i + 1}、{v.companyName}
                          </h3>
                        ))}
                      </div>
                    }
                    title={null}
                    trigger="hover"
                  >
                    &emsp;&emsp; <a>{(MainBodyList || []).length + "家关联主体"}</a>
                  </Popover>
                ) : (
                  ""
                )}
              </Col>
              <Col span={8}>
                服务律师团名称：{companyInfo.teamName || "暂无"}
              </Col>
              <Col span={8}>
                联系人：{companyInfo.linkman || "暂无"}
              </Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                联系电话：{companyInfo.mobileNo || "暂无"}
                &emsp;
                补充电话： <EditMobileNo ExtraMobileNo={companyInfo.extraMobileNo}
                  refreshTable={this.getData}
                  legalCloudId={detailCustId}
                />
              </Col>
              <Col span={8}>
                客户状态：
                {companyInfo.customStatus == 1
                  ? "履约中"
                  : companyInfo.customStatus == 2
                    ? "已到期"
                    : "暂无"}
              </Col>
              <Col span={8}>
                企业经营地：{companyInfo.province || "暂无"}
                {companyInfo.city || ""}
                <EditModule record={companyInfo} refreshTable={this.getData}
                  type={"1"}
                  legalCloudId={detailCustId} />
              </Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                代理商：{companyInfo.brokerName || "暂无"}
              </Col>
              <Col span={8}>
                法务助理：
                {companyInfo.assistants &&
                  companyInfo.assistants.length
                  ? companyInfo.assistants.join("、")
                  : "暂无"}
              </Col>
              <Col span={8}>
                企业来源：
                {companyInfo.sourceType
                  ? filter.PayCompanyManageSourceType(
                    companyInfo.sourceType
                  )
                  : "暂无"}
              </Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                订单数：
                {companyInfo.orderCount
                  ? companyInfo.orderCount
                  : "暂无"}
              </Col>
              <Col span={8}>
                首要需求：
                {companyInfo.firstDemand
                  ? filter.FirstDemandType(
                    companyInfo.firstDemand
                  )
                  : "暂无"}
              </Col>
              <Col span={8}>
                业务员：{companyInfo.brokerUserName || "-"}
              </Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>
                所属行业：{companyInfo.firstCategory || "暂无"}
                {companyInfo.secondCategory || ""}
                {companyInfo.thirdCategory || ""}
                {companyInfo.fourCategory || ""}
                <EditModule record={companyInfo} refreshTable={this.getData} legalCloudId={detailCustId} type={"2"} />
              </Col>
              <Col span={8}>
                企业规模：{companyInfo.companySpec ? filter.CompanySpec(companyInfo.companySpec) : '暂无'}
              </Col>
              <Col span={8} style={{ display: 'flex' }}>
                案件委托次数：
                {/* {companyInfo.caseCount || 0} &nbsp;&nbsp;{(companyInfo.caseCount || '') && <a onClick={() => this.getCase(1)}>查看案件</a>} */}
                <span>
                  <Spin spinning={wtLoading}>
                    {this.state.wtNum || '-'}
                    {
                      this.state.wtNum > 0 && (<a style={{ marginLeft: 15 }} onClick={() => this.getCase(1)}>查看案件</a>)
                    }
                  </Spin>
                </span>
              </Col>
            </Row>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>企业情况概述</div>
            <Table
              dataSource={this.state.legalCompanyBasicInfo}
              columns={[
                {
                  title: "客户等级",
                  dataIndex: "level",
                  render: (text) => filter.PayCompanyLevel(text)
                },
                {
                  title: "主要联系人姓名",
                  dataIndex: "masterLinkMan",
                  render: (text) => text || '-'
                },
                {
                  title: "性别",
                  dataIndex: "sex",
                  render: (text) => text || '-'
                },
                {
                  title: "年龄",
                  dataIndex: "age",
                  render: (text) => text || '-'
                },
                {
                  title: "职务",
                  dataIndex: "job",
                  render: (text) => text || '-'
                },
                {
                  title: "整体法律意识",
                  dataIndex: "lawSense",
                  render: (text) => text || '-'
                },
                {
                  title: "服务认可度",
                  dataIndex: "serviceAccept",
                  render: (text) => text || '-'
                },
                {
                  title: "付费能力",
                  dataIndex: "paymentPower",
                  render: (text) => text || '-'
                },
                {
                  title: "营收规模",
                  dataIndex: "incomeScale",
                  render: (text) => text || '-'
                },
                {
                  title: "备注",
                  dataIndex: "memo",
                  width: 1100,

                },
              ]}
              size="middle"
              pagination={false}
              scroll={{ x: 'max-content' }}
              bordered={true}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>季度服务报告</div>
            <Table
              dataSource={this.state.reportList}
              columns={[
                {
                  title: "服务周期",
                  dataIndex: "legalStartDate",
                  render: (t, r) => {
                    return <span>{filter.timetrans(t, 'day')}~{filter.timetrans(r.legalEndDate, 'day')}</span>
                  }
                },

                {
                  title: "操作时间",
                  dataIndex: "reportTime",
                  render: (t) => {
                    return <span>{t || '-'}</span>
                  }
                },
                {
                  title: "操作人",
                  dataIndex: "operName",
                  render: (t) => '总部云法务'
                },
                {
                  title: "操作",
                  dataIndex: "操作",
                  render: (text, record) => (
                    <span>
                      {
                        record.reportStatus === 1 ? (<Link onClick={() => this.addFinalReport(record)}>服务报告【下载】</Link>) : '-'
                      }

                    </span>
                  ),
                },
              ]}
              size="middle"
              pagination={false}
              bordered={true}
            // onChange={this.getDataList}

            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>套餐购买信息</div>
            {packageVO.purchaseSpec ? (
              <Row style={dStyle}>
                <Col span={8}>
                  套餐：
                  {filter.legalspecification(
                    packageVO.purchaseSpec
                  )}
                  ￥ {packageVO.costAmt}元
                </Col>
                <Col span={8}>
                  购买时间：
                  {packageVO.payTime
                    ? new Date(packageVO.payTime)
                      .toLocaleString("chinese", {
                        hour12: false,
                      })
                      .replaceAll("/", "-")
                    : "-"}
                </Col>
                <Col span={8}>
                  到期时间：
                  {packageVO.nextCheckDate
                    ? new Date(packageVO.nextCheckDate)
                      .toLocaleString("chinese", {
                        hour12: false,
                      })
                      .replaceAll("/", "-")
                    : "-"}
                </Col>
              </Row>
            ) : (
              <Row style={dStyle}>
                <Col span={8}>暂无套餐</Col>
              </Row>
            )}
          </div>

          <div style={cStyle}>
            <div style={tStyle}>合作相关文件</div>
            <div type="flex">
              <Ossupload
                callbackData={{ test: "123" }}
                callback={async (
                  filename,
                  file,
                  info,
                  callbackData
                ) => {
                  if (info.status == 200) {
                    try {
                      let send = {
                        docSize: "",
                        docUrl:
                          window.winhc_oss_url +
                          filename,
                      };

                      try {
                        let res = await Axios(
                          "post",
                          "/brokeruser/upload/" +
                          detailCustId,
                          send
                        );
                        console.log("上传文件==", res);
                        if (res.code === "200") {
                          message.success(
                            res.message
                          );
                          this.getData(); // 刷新列表数据;
                        } else {
                          message.error(res.message);
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    } catch (err) {
                      console.log("err", err);
                      message.error(err.message);
                    }
                  }
                }}
                beforeUpload={(file, up) => {
                  console.log(file, up);
                  if (file.size > 1024 * 1024 * 10 * 30) {
                    message.error("请选择小于300MB文件！");
                    return false;
                  }
                }}
              >
                {QuanXian ? (
                  <Button
                    style={{
                      marginTop: "-20px",
                      marginRight: "20px",
                    }}
                  >
                    <UploadOutlined />
                    上传文件
                  </Button>
                ) : (
                  ""
                )}
              </Ossupload>
              <FlieList
                fileList={this.state.brokeFileList}
                QuanXian={QuanXian}
                close={async (url, index, id) => {
                  try {
                    let res = await Axios(
                      "delete",
                      "/brokeruser/del/" + id,
                      {
                        corpFileUrl: url,
                      }
                    );
                    if (res.code === "200") {
                      message.success(res.message);
                      this.getData(); // 刷新列表数据;
                    } else {
                      message.error(res.message);
                    }
                  } catch (err) {
                    message.error(err.message);
                  }
                }}
              />
            </div>
            <div style={{ marginTop: 5 }}>
              支持扩展名：.rar .zip .doc .docx .pdf .jpg...
            </div>
          </div>
          <div style={cStyle}>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane
                tab={
                  <span style={TabsStyle}>线下签约合同</span>
                }
                key="1"
              >
                <Row style={dStyle}>
                  <Col span={24}>
                    订单号：
                    {legalServiceOrder.orderId || "暂无"}
                  </Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={24}>
                    合同文件：
                    {legalServiceOrder &&
                      legalServiceOrder.offlineSignContract
                      ? legalServiceOrder.offlineSignContract
                        .split(",")
                        .map((v) => (
                          <FlieList
                            fileList={[
                              { docUrl: v },
                            ]}
                            hoverBg="fff"
                          />
                        ))
                      : "暂无合同"}
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<span style={TabsStyle}>电子合同</span>}
                key="2"
              >
                <Row style={dStyle}>
                  <Col span={24}>
                    订单号：
                    {esigns && esigns.length
                      ? esigns[0].orderId
                      : "暂无"}
                  </Col>
                </Row>
                <Row style={dStyle}>
                  <Col span={24}>
                    合同文件：
                    {esigns && esigns.length
                      ? esigns.map((v) => {
                        return v.fileInfos.map(
                          (k) => (
                            <FlieList
                              fileList={[
                                {
                                  docUrl: k.fileDownloadUrl,
                                },
                              ]}
                              hoverBg="fff"
                              name={k.fileName}
                            />
                          )
                        );
                      })
                      : "暂无合同"}
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div style={cStyle}>
            <div style={tStyle}>企业专项风险体检</div>
            <Table
              dataSource={legalCloudRiskExamRecords}
              columns={[
                {
                  title: "ID",
                  dataIndex: "id",
                },
                {
                  title: "风险体检项目名称",
                  dataIndex: "examType",
                  render: text => filter.ExamType(text),
                },
                {
                  title: "体检时间",
                  dataIndex: "timeCreated",
                  render: text => text || "-",
                },
              ]}
              size="middle"
              pagination={
                legalCloudRiskExamRecords.length > 10
                  ? {
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                  }
                  : false
              }
              bordered={true}
              onChange={this.getDataList}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>服务明细</div>
            <Table
              dataSource={this.state.bizOperHis}
              columns={[
                {
                  title: "ID",
                  dataIndex: "id",
                  width: "80px",
                },
                {
                  title: "服务项",
                  dataIndex: "legalServiceItemName",
                  width: "80px",
                },
                {
                  title: "律师",
                  dataIndex: "lawyerName",
                  width: "80px",
                },
                {
                  title: "业务领域",
                  dataIndex: "businessDomain",
                  width: "80px",
                  render: (r) => filter.BusinessDomain(r),
                },
                {
                  title: "服务方式",
                  dataIndex: "legalServiceMode",
                  width: "80px",
                  render: (r) => filter.LegalServiceMode(r),
                },
                {
                  title: "服务时长",
                  dataIndex: "legalServiceHour",
                  width: "80px",
                  render: (r) => (r ? r + " " + "小时" : "-"),
                },
                {
                  title: "服务内容",
                  dataIndex: "memo",
                  width: "80px",
                },
                {
                  title: "备注",
                  dataIndex: "remark",
                  width: "80px",
                },
                {
                  title: "服务时间",
                  dataIndex: "timeCreated",
                  width: "80px",
                },
              ]}
              size="middle"
              pagination={
                this.state.bizOperHis.length > 10
                  ? {
                    size: "large",
                    showQuickJumper: true,
                    pageSize: 10,
                  }
                  : false
              }
              bordered={true}
              onChange={this.getDataList}
            />
          </div>
          <FollowUpRecords
            record={companyInfo}
            refreshTable={this.getData} DataList={followRecordVOS} id={detailCustId} type='intention' />
          <div style={cStyle}>
            <div style={tStyle}>操作记录</div>
            <Tables
              code={1}
              detailCustId={this.props.match.params.id}
              ref={this.TablesRef}
            />
          </div>
        </div>
        {/* TODO */}
        <Modal
          title="案件委托"
          open={this.state.wTvisible}
          footer={null}
          onOk={() => {
          }}
          onCancel={() => {
            this.setState({ wTvisible: false });
          }}
          width='80%'
        >
          <Table
            loading={this.state.wTLoading}
            dataSource={this.state.CaseData}
            columns={[
              {
                title: "案件ID",
                dataIndex: "caseId",
              },
              popoverTable(16, {
                title: "原告",
                dataIndex: "creditorName",
              }),
              popoverTable(16, {
                title: "被告",
                dataIndex: "debtorName",
              }),
              {
                title: "案由",
                dataIndex: "caseTypeDesc",
              },
              {
                title: "标的(万元)",
                dataIndex: "caseAmt",
                render: (text, record, index) => window._SYT_util.formatMoney(window._SYT_util.math.accDiv(text * 1, 10000), 2)
              },
              {
                title: "申请日期",
                dataIndex: "applyDate",
                render: (text) => (text ? <span> {filter.date(text)}</span> : "-"),
              },
              {
                title: "状态",
                dataIndex: "caseStatusDese",
              },
              {
                title: "操作",
                dataIndex: "caseStatusDese",
                render: (text, record) => <Link to={"/CaseManagementDetail/" + record.caseId}>查看</Link>,
              },
            ]}
            size="middle"
            pagination={
              this.state.CaseData.length >= 10
                ? {
                  size: "large",
                  showQuickJumper: true,
                  pageSize: 10,
                  total: this.state.CasetotalPage
                }
                : false
            }
            bordered={true}
            onChange={({ current }) => this.getCase(current)}
          />
        </Modal>
      </div>
    );
  }
}

let Main = connect(
  (state) => {
    return state;
  },
  (dispatch) => ({
    actions: bindActionCreators({}, dispatch),
  })
)(MainM);
export default Main;
