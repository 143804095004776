import React, { Component } from "react";
import {
  Row,
  Breadcrumb,
  Button,
  Form,
  message,
  Modal,
  Input,
  Select,
  Col,
  Radio,
  Popover,
  Table,
  TreeSelect,
  Alert,
  DatePicker,
  InputNumber,
  Cascader,
} from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import filter from "../../common/filter";
import moment from "moment";
import Login from "../login/login";
import { change_operInfo } from "../../redux/actions/operInfo";
import { bindActionCreators } from "redux";

const { Option } = Select;
let sendBody = {};
const { RangePicker } = DatePicker;

class OperatorManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      currentPage: window.routerCache.OperatorManage.currentPage || 1,
    };
    sendBody = window.routerCache.OperatorManage.sendBody || {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState({
      tData: data,
      totalNum: totalNum,
      currentPage: currentPage,
    });
  };
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });

      let send = sendBody;
      // send.workType = window.routerCache.OperatorManage.send_workType || '';
      // sendBody.status = window.routerCache.OperatorManage.workType_status || '';
      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      let res = await Axios("get", "/brokeruser/operateList", send);
      this.setState({
        loading: false,
        tData: res.data.dataList,
        totalNum: res.data.totalNum,
      });
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };

  handleTableChange = async (pagination) => {
    this.setState({ loading: true });
    try {
      let send = sendBody;
      send.pageNum = pagination.current + "";
      send.pageSize = "10";
      // send.workType = window.routerCache.OperatorManage.send_workType || '';
      // sendBody.status = window.routerCache.OperatorManage.workType_status || '';
      let res = await Axios("get", "/brokeruser/operateList", send);
      this.setState({
        loading: false,
        tData: res.data.dataList,
        totalNum: res.data.totalNum,
        currentPage: pagination.current,
      });
    } catch (err) {
      // message.error(err.message);
      this.setState({ loading: false });
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    this.refreshTable();
  }

  componentWillUnmount () {
    window.routerCache.OperatorManage.currentPage = this.state.currentPage;
    window.routerCache.OperatorManage.sendBody = sendBody;
  }

  render () {
    // let Quanxian=  this.props.operInfo.operInfo.roles.includes('ROLE_BrokerManage');
    let Quanxian = true;
    let columns = [
      {
        title: "ID",
        dataIndex: "brokerId",
        width: 170,
      },
      popoverTable(6, {
        title: "企业名称",
        dataIndex: "brokerName",
        width: 90,
      }),
      popoverTable(6, {
        title: "联系人",
        dataIndex: "linkman",
        width: 90,
      }), {
        title: "联系电话",
        dataIndex: "mobileNo",
        width: 110,
      },
      {
        title: "地区",
        dataIndex: "province",
        width: 140,
        render: (t, r) => t + "  " + (r.city || '') + '  ' + (r.district || ''),
      },

      {
        title: "推荐人",
        dataIndex: "rcmName",
        width: 90,
      },
      {
        title: "类型",
        dataIndex: "brokerType",
        width: 90,
        render: (text, record) => filter.brokeBelong(text),
      },
      {
        title: "合作开始时间",
        dataIndex: "cooperationDate",
        width: 150,
        render: (t) => filter.date(t)

      }, {
        title: "合作结束时间",
        dataIndex: "endDate",
        width: 150,
        render: (t) => filter.date(t)

      },
      {
        title: "操作",
        dataIndex: "operate",
        width: 90,
        render: (text, record, index) => (
          <Link to={"/OperatorManageDetail/" + record.brokerId}>查看</Link>
        ),
      },
    ];
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>运营商管理</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">运营商管理</span>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div className="scrollMain">
            <Search refreshTableByData={this.refreshTableByData} />
            <div style={{ backgroundColor: "#fff" }}>
              <Table
                style={{ alignItems: "center" }}
                dataSource={this.state.tData}
                rowKey="orderId"
                columns={columns}
                size="middle"
                pagination={{
                  size: "large",
                  showQuickJumper: true,
                  pageSize: 10,
                  total: parseInt(this.state.totalNum),
                  current: Number(this.state.currentPage),
                  showTotal: (total) => {
                    return "共 " + total + " 条记录 ";
                  },
                }}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default connect(
  (state) => {
    return state;
  },
  (dispatch) => ({})
)(OperatorManage);

class SearchM extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      province: sendBody.province || "",
      city: sendBody.city || "",
      qingkong: false,
    };
  }

  // 查询
  query = async () => {
    let _this = this;
    sendBody = this.formRef.current.getFieldsValue();
    // if (sendBody.toDateTime) {
    //     sendBody.fromDateTime = filter.timetrans(sendBody.toDateTime[0], 'day') + ' 00:00:00';
    //     sendBody.toDateTime = filter.timetrans(sendBody.toDateTime[1], 'day') + ' 00:00:00';
    // } else if (this.state.eDate) {
    // }
    if (this.state.city) {
      sendBody.city = this.state.city;
    }
    if (this.state.province) {
      sendBody.province = this.state.province;
    }
    if (this.state.county) {
      sendBody.district = this.state.county;
    }

    delete sendBody.date;
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    // sendBody.workType = window.routerCache.OperatorManage.send_workType || '';
    // sendBody.status = window.routerCache.OperatorManage.workType_status || '';
    try {
      console.log(sendBody);
      let res = await Axios("get", "/brokeruser/operateList", sendBody);

      _this.props.refreshTableByData(
        res.data.dataList,
        res.data.totalNum,
        1
      );
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearOptions = async () => {
    // 清空的时候清空地区
    this.setState(
      {
        province: "",
        city: "",
        county: '',
        qingkong: true,
      },
      () => {
        this.setState({
          qingkong: false,
        });
        this.formRef.current.resetFields();

      }
    );
    sendBody = {};
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    this.props.refreshTableByData([], 1, 1);
    console.log(sendBody);
    try {
      let res = await Axios("get", "/brokeruser/operateList", sendBody);

      this.props.refreshTableByData(
        res.data.dataList,
        res.data.totalNum,
        1
      );
    } catch (err) {
      // message.error(err.message);
    }
  };
  // 组件渲染后获取外界数据(GET)
  componentDidMount () {

  }

  render () {
    /*控制查询按钮状态*/
    const { userRole } = this.props.operInfo;
    return (
      <div className="search_like_antD" style={{ marginTop: "-8px" }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
          <Row
            type="flex"
            align="middle"
            style={{ padding: "4px 0" }}
          >
            <Col span={8}>
              <Form.Item
                label="企业名称："
                style={{ width: "100%" }}
                name="companyName"
                initialValue={sendBody.companyName}>

                <Input
                  placeholder="请输入企业名称"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="手机号" style={{ width: "100%" }}
                name="mobileNo"
                initialValue={sendBody.mobileNo}>
                <Input
                  placeholder="请输入手机号"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="所属地区："
                style={{ width: "100%" }}
                name="province"
                initialValue={sendBody.province}>
                <AreaSelect
                  bodyStyle={{ width: "80%" }}
                  province={this.state.province}
                  city={this.state.city}
                  county={this.state.county}
                  Hadoop={false}
                  allowClear
                  qingkong={this.state.qingkong}
                  select={(province, city, county) => {
                    console.log(province, city, county);
                    sendBody.province = province;
                    sendBody.city = city;
                    sendBody.district = county;
                    this.setState({
                      province: province || "",
                      city: city || "",
                      county: county || '',
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            type="flex"
            align="middle"
          >
            <Col span={8}>
              <Form.Item
                label="联系人："
                style={{ width: "100%" }}
                name="linkMan"
                initialValue={sendBody.linkMan}>

                <Input
                  placeholder="请输入联系电话"
                  type="text"
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <Row
                type="flex"
                justify="end"
                align="middle"
                style={{ width: "85%", height: '30px' }}
              >
                <Form.Item style={{ marginRight: '16px' }}
                >
                  <Button
                    type="primary"
                    onClick={this.query}
                  >
                    查询
                  </Button>
                </Form.Item>
                <Form.Item >
                  <Button onClick={this.clearOptions}>
                    重置
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <div>
            <Row type="flex" justify="space-between" align="middle"
              style={window.routerCache.PersonQuery.workTypeStr ? {
                paddingBottom: "10px"
              } : { paddingBottom: "40px" }}>
              {window.routerCache.PersonQuery.workTypeStr ? <Alert message={<div>
                <span>{window.routerCache.PersonQuery.workTypeStr}</span>&nbsp;&nbsp;<a
                  onClick={this.clearLawfirm}>清空</a>&nbsp;&nbsp;
              </div>} type="info" showIcon style={{ marginTop: '12px' }} /> : ''}
            </Row>
          </div>
        </Form>
        <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
      </div>
    );
  }
}

let Search = connect(
  (state) => {
    return state;
  },
  (dispatch) => ({})
)(SearchM);
