import React, { Component } from 'react';
import {
  Row,
  Col,
  Breadcrumb,
  message,
  Button,
  Form,
  Popconfirm,
  Input,
  Select,
  Tooltip,
  Table,
  Popover,
  TreeSelect
} from 'antd';
import { Axios } from '../../axios';
import { Link } from 'react-router-dom';
import filter from "../../common/filter";

const { Option } = Select;

export default class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.match.params.id,
      customerDet: {},
      followList: [],
      followListTotalNum: 0,
      followListLoading: false,
      followListPage: 1,
      serviceList: [],
      serviceListTotalNum: 0,
      serviceListLoading: false,
      serviceListPage: 1,
    }
  }

  componentDidMount () {
    this.getDet();
    this.getFollowList(1);
  }

  getDet = async () => {
    try {
      let res = await Axios('get', '/customer/detail', {
        userId: this.state.userId,
      })
      if (res.code == '200') {
        this.setState({
          customerDet: res.data
        })
        if (res.data.id) {
          this.getServiceList(1);
        }
      } else {
        message.error(res.message)
      }
    } catch (err) {
      console.log(err)
    }
  }

  getFollowList = async (pageNum) => {
    try {
      this.setState({
        followListLoading: true
      })
      let res = await Axios('get', '/customer/follow/page', {
        userId: this.state.userId,
        pageSize: 10,
        pageNum: pageNum || this.state.followListPage
      })
      if (res.code == '200') {
        this.setState({
          followList: res.data.dataList,
          followListTotalNum: res.data.totalNum,
          followListPage: pageNum || this.state.followListPage,
        })
      } else {
        message.error(res.message)
      }
      this.setState({
        followListLoading: false
      })
    } catch (err) {
      console.log(err)
      this.setState({
        followListLoading: false
      })
    }
  }
  getServiceList = async (pageNum) => {
    try {
      this.setState({
        serviceListLoading: true
      })
      let res = await Axios('get', '/customer/service/page', {
        id: this.state.customerDet.id,
        pageSize: 10,
        pageNum: pageNum || this.state.serviceListPage
      })
      if (res.code == '200') {
        this.setState({
          serviceList: res.data.dataList,
          serviceListTotalNum: res.data.totalNum,
          serviceListPage: pageNum || this.state.serviceListPage,
        })
      } else {
        message.error(res.message)
      }
      this.setState({
        serviceListLoading: false
      })
    } catch (err) {
      console.log(err)
      this.setState({
        serviceListLoading: false
      })
    }
  }


  render () {

    const cStyle = {
      padding: '12px 24px 12px 24px',
      marginBottom: '15px',
      borderRadius: '4px',
      background: '#fff',
      fontSize: "14px"
    }
    const tStyle = {
      color: '#222',
      lineHeight: '30px',
      fontWeight: '650',
      fontSize: '16px',
      margin: '0px -24px 15px -24px',
      borderBottom: "1px solid #efe5e5",
      padding: "0 24px 8px 24px"
    }
    const dStyle = {
      color: '#222',
      lineHeight: '30px',
      margin: '10px 0px 0px 0px',
    }
    const { customerDet } = this.state;
    return (
      <div className="pageContainer">
        <Row className="headNav" type="flex" justify="space-between">
          <Row>
            <Breadcrumb separator=">">
              <Breadcrumb.Item><Link to="/CustomerQuery">客户管理</Link></Breadcrumb.Item>
              <Breadcrumb.Item>客户详情</Breadcrumb.Item>
            </Breadcrumb>

            <Row className='title'>
              <span className='name'>{customerDet.companyName}</span>
            </Row>
          </Row>

          <Row>
            <div style={{ textAlign: "end" }}>状态</div>
            <h2>  {customerDet.legalServiceStatus == '1' ? '已购买' : customerDet.legalServiceStatus == '2' ? '未购买' : ''}</h2>
          </Row>

        </Row>
        <div style={{ padding: '16px 24px' }}>
          <div style={cStyle}>
            <div style={tStyle}>基本信息</div>
            <Row style={dStyle}>
              <Col span={8}>客户ID：{customerDet.id || '--'}</Col>
              <Col span={8}>客户名称：{customerDet.companyName || '--'}</Col>
              <Col span={8}>联系人：{customerDet.linkman || '--'}</Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>联系电话：{customerDet.mobileNo || '--'}</Col>
              <Col span={8}>代理商：{customerDet.brokerName || '--'}</Col>
              <Col span={8}>业务员：{customerDet.userName || '--'}</Col>
            </Row>
            <Row style={dStyle}>
              <Col span={8}>所在地：{customerDet.province || '--'} {customerDet.city || ''}</Col>
              <Col
                span={16}>所属行业：{customerDet.firstCategory || '-'} {customerDet.secondCategory || ''} {customerDet.thirdCategory || ''} {customerDet.fourCategory || ''}
              </Col>
            </Row>
          </div>

          {customerDet.applyTime || customerDet.expireTime ? <div style={cStyle}>
            <div style={tStyle}>申请试用信息</div>
            <Row style={dStyle}>
              <Col
                span={8}>申请试用时间：{customerDet.applyTime ? filter.timetrans(customerDet.applyTime) : '--'}</Col>
              <Col
                span={8}>试用到期时间：{customerDet.expireTime ? filter.timetrans(customerDet.expireTime) : '--'}</Col>
            </Row>
          </div> : ''}

          {customerDet.purchaseSpec ? <div style={cStyle}>
            <div style={tStyle}>套餐购买信息</div>
            <Row style={dStyle}>
              <Col
                span={8}>套餐：{customerDet.purchaseSpec ? filter.legalspecification(customerDet.purchaseSpec) + ' ' + (customerDet.costAmt || '') + '元' : '--'}</Col>
              <Col
                span={8}>购买时间：{customerDet.payTime ? filter.timetrans(customerDet.payTime) : '--'}</Col>
              <Col
                span={8}>到期时间：{customerDet.legalEndTime ? filter.timetrans(customerDet.legalEndTime) : '--'}</Col>
            </Row>
          </div> : ''}

          <div style={cStyle}>
            <div style={tStyle}>跟进信息</div>
            <Table
              dataSource={this.state.followList}
              columns={[{
                title: '序号',
                dataIndex: 'lawyerUserId',
                width: 70,
                render: (t, r, i) => (i + 1) * this.state.followListPage
              }, {
                title: '跟进记录',
                dataIndex: 'content',
              }, {
                title: '跟进时间',
                dataIndex: 'timeCreated',
                render: t => filter.timetrans(t)
              }, {
                title: '跟进人',
                dataIndex: 'userName',
              }]}
              size="middle"
              pagination={this.state.followList > 10 ? {
                size: 'large',
                showQuickJumper: true,
                pageSize: 10,
                total: Number(this.state.followListTotalNum),
                current: Number(this.state.followListPage),
              } : false}
              loading={this.state.followListLoading}
              bordered={true}
              onChange={(pagination) => {
                this.getFollowList(pagination.current)
              }}
            />
          </div>
          <div style={cStyle}>
            <div style={tStyle}>服务律师及法务信息</div>
            <Table
              dataSource={customerDet.legalTeamMemberVOList || []}
              columns={[{
                title: '序号',
                dataIndex: 'lawyerUserId',
                width: 70,
                render: (t, r, i) => i + 1
              }, {
                title: '姓名',
                dataIndex: 'lawyerUserName',
              }, {
                title: '角色',
                dataIndex: 'teamMemberRole',
                render: t => t == '1' ? '团长' : t == '2' ? '律师' : t == '3' ? '法务' : ''
              }]}
              size="middle"
              pagination={false}
              bordered={true}
            />
          </div>

          <div style={cStyle}>
            <div style={tStyle}>律师服务明细</div>
            <Table
              dataSource={this.state.serviceList}
              columns={[{
                title: '序号',
                dataIndex: '序号',
                width: 50,
                render: (t, r, i) => (i + 1) * this.state.serviceListPage
              }, {
                title: '服务项',
                dataIndex: 'legalServiceItemName',
              }, {
                title: '律师',
                dataIndex: 'lawyerName',
              }, {
                title: '业务领域',
                dataIndex: 'businessDomain',
              }, {
                title: '服务方式',
                dataIndex: 'legalServiceMode',
              }, {
                title: '服务时长',
                dataIndex: 'legalServiceHour',
              }, {
                title: '服务内容',
                dataIndex: 'remark',
              }, {
                title: '备注',
                dataIndex: 'memo',
              }, {
                title: '服务时间',
                dataIndex: 'timeCreated',
                render: t => filter.timetrans(t)
              }]}
              size="middle"
              pagination={this.state.serviceList > 10 ? {
                size: 'large',
                showQuickJumper: true,
                pageSize: 10,
                total: Number(this.state.serviceListTotalNum),
                current: Number(this.state.serviceListPage),
              } : false}
              loading={this.state.serviceListLoading}
              bordered={true}
              onChange={(pagination) => {
                this.getServiceList(pagination.current)
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}














